<template>
  <b-row>
    <b-col v-for="item in data" :key="item.id" cols="6">
      <div @click="goTo(item.id)" class="m-map-placeholder">
        <img :src="map" class="img-fluid" />
      </div>
      <p class="text-right">{{ item.name }}</p>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'MapCities',
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      map: require("@/assets/images/icons/map_box.png"),
      width: "28px"
    }
  },
  components: {

  },
  computed: {
    authenticated() {
      return this.$store.state.AppActiveUser.first_name != undefined
    }
  },
  methods: {
    goTo(id) {
      if(!this.authenticated) {
        this.$store.dispatch("city/setCity", id)
        return
      }
      
      this.$router.push('/city/'+id)
    }
  }
}
</script>
