<template>
  <b-container fluid class="search-content">
    <map-cities :data="cities" />
    <p>يقوم فريق العمل بتحديث البيانات بشكل دوري. اذا لم تكن المدينة المطلوب البحث فيها من ضمن الاختيارات يرجى مراجعة التطبيق لاحقاً</p>
  </b-container>
</template>
<script>
import MapCities from '@/components/MapCities.vue'

export default {
  title: "مخطط",
  data() {
    return {
    }
  },
  components: {
    MapCities
  },
  computed: {
    currentPage() {
      return this.$store.state.city.meta.current_page
    },
    pagination() {
      return this.$store.state.city.meta.per_page
    },
    cities() {
      return this.$store.state.city.cities.filter((city) => city['mapbox_style_url'] != null)
    },
    authenticated() {
      return this.$store.state.AppActiveUser.first_name != undefined
    }
  },
  methods: {
    fetchCities() {
      let loader = this.$loading.show({opacity: 0.9})
      const payload = {
        meta: {
          page: this.currentPage,
          paginate: this.pagination
        }
      }
      this.$store.dispatch("city/fetchCities", payload)
      .then(() => {
         loader.hide()
      })
      .catch(() => {
        loader.hide()
      })
    },
  },
  created() {
    this.fetchCities()
  },
  mounted() {

  }
}
</script>

<style lang="scss">
#map {
  width: 100%;
  height: 100vh;
}
</style>
